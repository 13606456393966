<template>
    <CompTable title="全局搜索功能管理" :columns="columns" :formComponent="formComponent" :keys="{ page: 'currentPage' }" edit-key="elementId" table-api="/gateway/apps/basic/api/pc/element/queryByPage" delete-api="/gateway/apps/basic/api/pc/element/deleteAppElement">
        <template v-slot:search="evt">
            <Input v-model="evt.search.elementName" placeholder="功能名称" />
        </template>
    </CompTable>
</template>

<script>
import CompTable from "../jointly/components/comp-table.vue"
import Request from "../jointly/utils/request"

import Form from "./form.vue"

export default {
    components: { CompTable },

    data() {
        return {
            formComponent: Form,
            columns: [
                {
                    title: "功能名称",
                    key: "elementName",
                },
                {
                    title: "功能别名",
                    key: "elementAlias",
                },
                {
                    title: "功能类型",
                    render: (h, params) => ({ 1: "功能类", 2: "应用类", 3: "第三方链接" }[params.row.elementType] || "-"),
                },
                {
                    title: "跳转链接",
                    key: "elementLink",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                },
                {
                    title: "APPID",
                    key: "appId",
                },
                {
                    title: "图标",
                    render: (h, params) => {
                        if (!params.row.icon) {
                            return "-"
                        }

                        return h("img", {
                            attrs: {
                                src: params.row.icon,
                                width: 40,
                                height: 40,
                            },
                        })
                    },
                },
                {
                    title: "排序",
                    key: "sort",
                },
                {
                    title: "是否启用",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.elementStatus == 1,
                            },
                            on: {
                                "on-change": value => {
                                    Request.post(
                                        "/gateway/apps/basic/api/pc/element/updateStatus",
                                        {
                                            elementId: params.row.elementId,
                                            elementStatus: value ? 1 : 0,
                                        },
                                        {
                                            json: true,
                                            success: "修改成功",
                                        }
                                    )
                                },
                            },
                        })
                    },
                },
            ],
        }
    },
}
</script>
