<template>
    <CompForm
        ref="form"
        title="功能"
        :forms="forms"
        id-key="elementId"
        submit-api="/gateway/apps/basic/api/pc/element/createAppElement"
        edit-api="/gateway/apps/basic/api/pc/element/updateAppElement"
        detail-api="/gateway/apps/basic/api/pc/element/getByElementId"
        :submit-before="onSubmitBefore"
        @on-submit="$emit('on-submit')"
    ></CompForm>
</template>

<script>
import CompForm from "../jointly/components/CompForm.vue"

export default {
    components: { CompForm },

    data() {
        return {
            forms: [
                {
                    type: "input",
                    title: "功能名称",
                    name: "elementName",
                    required: true,
                },
                {
                    type: "label",
                    title: "功能别名（按回车键确认）",
                    name: "elementAlias",
                },
                {
                    type: "select",
                    title: "模块类型",
                    name: "elementType",
                    required: true,
                    options: [
                        {
                            label: "功能类",
                            value: "1",
                        },
                        {
                            label: "应用类",
                            value: "2",
                        },
                        {
                            label: "第三方链接",
                            value: "3",
                        },
                    ],
                },
                {
                    type: "textarea",
                    title: "跳转链接",
                    name: "elementLink",
                },
                {
                    type: "input",
                    title: "栏目编码",
                    name: "columnCode",
                },
                {
                    type: "input",
                    title: "APPID",
                    name: "appId",
                },
                {
                    type: "upload",
                    title: "图标",
                    name: "icon",
                },
                {
                    type: "input",
                    title: "排序",
                    name: "sort",
                },
            ],
        }
    },

    methods: {
        open(id, data, extra) {
            this.$refs.form.open(id, data, extra)
        },

        onSubmitBefore(data) {
            return {
                ...data,
                elementAlias: data.elementAlias ? data.elementAlias.join(",") : "",
            }
        },
    },
}
</script>
